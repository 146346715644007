import { AsyncPipe, DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { FeatureFlags, FeatureProviderService } from '@rma/generic/util/feature-flags';
import { BrowseMode, BrowseModeService } from '@rma/site/private/util-browse-mode';
import { Angulartics2OnModule } from 'angulartics2';
import { Observable } from 'rxjs';

@Component({
  selector: 'rma-header-browse-mode-toggle',
  templateUrl: './header-browse-mode-toggle.component.html',
  styleUrls: ['./header-browse-mode-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, Angulartics2OnModule, AsyncPipe, TranslocoPipe],
})
export class HeaderBrowseModeToggleComponent {
  public readonly browseModes = BrowseMode;

  public readonly browseMode$: Observable<BrowseMode>;
  public readonly enableFinance$: Observable<boolean>;

  public constructor(
    private readonly browseModeService: BrowseModeService,
    private readonly featureProviderService: FeatureProviderService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router,
  ) {
    this.browseMode$ = this.browseModeService.mode$;
    this.enableFinance$ = this.featureProviderService.getFeature$(FeatureFlags.EnableFinance, false);
  }

  public setMode(mode: BrowseMode) {
    const route = this.browseModeService.setMode(mode);
    this.router.navigate(route);
  }
}
