import { Injectable } from '@angular/core';
import { Environment } from '@rma/generic/util/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { excludedLocations, SocialLink } from './footer.const';
import { LayoutService, LocationFooterLink } from './layout.service';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  constructor(
    private readonly layoutService: LayoutService,
    private readonly config: Environment,
  ) {}

  public getLocationLinks(): Observable<LocationFooterLink[]> {
    return this.layoutService
      .getLocationFooterLinks()
      .pipe(map((x) => x.filter((location) => !excludedLocations.includes(location.name)).sort((a, b) => a.name.localeCompare(b.name))));
  }

  public getSocialLinks(): SocialLink[] {
    const { facebookUrl, twitterUrl, linkedinUrl, instagramUrl } = this.config.social;

    const list: SocialLink[] = [
      {
        label: 'Facebook',
        icon: 'social-facebook',
        href: facebookUrl,
      },
      {
        label: 'Instagram',
        icon: 'social-instagram',
        href: instagramUrl,
      },
      {
        label: 'LinkedIn',
        icon: 'social-linkedin',
        href: linkedinUrl,
      },
      {
        label: 'Twitter',
        icon: 'social-twitter',
        href: twitterUrl,
      },
    ];
    return list.filter((x) => x.href);
  }
}
