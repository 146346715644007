import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { CurrentUserService } from '@rma/accounts/user';
import { HeaderCompareAgentComponent } from '@rma/compare-agent/public-api/header';
import { RmaIconComponent } from '@rma/generic/ui/icon';
import { StaticImageUrlPipe } from '@rma/generic/util/environment';
import { FeatureFlags, FeatureProviderService } from '@rma/generic/util/feature-flags';
import { filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import { HeaderNavComponent } from '../feat-header-nav/header-nav.component';
import { HeaderUserComponent } from '../feat-header-user/header-user.component';
import { HeaderTrackPropertyComponent } from '../feat-track-property/header-track-property.component';

@Component({
  selector: 'rma-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    HeaderCompareAgentComponent,
    HeaderNavComponent,
    HeaderTrackPropertyComponent,
    HeaderUserComponent,
    MatButtonModule,
    RmaIconComponent,
    RouterLink,
    StaticImageUrlPipe,
    TranslocoPipe,
  ],
})
export class HeaderComponent {
  @Input()
  public basicHeader = false;

  @Output()
  public readonly toggle = new EventEmitter<void>();

  private readonly featureService = inject(FeatureProviderService);
  private readonly router = inject(Router);
  private readonly userService = inject(CurrentUserService);

  protected readonly landerUrl$ = this.router.events.pipe(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter((event: any) => event instanceof NavigationEnd),
    map(({ url }) => url),
    withLatestFrom(this.userService.isAuthenticated$),
    map(([url, isAuthenticated]) => url === '/' && !isAuthenticated),
    switchMap((showLander) => (showLander ? this.featureService.getFeature$(FeatureFlags.AgentLanderUrl, '') : of(''))),
  );

  public toggleMenu() {
    this.toggle.emit();
  }
}
