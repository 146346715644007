<nav>
  <div class="grid gap-x-8" [class.lg:grid-cols-2]="twoColumns" [class.h-[292px]]="limitTo()" [class.overflow-hidden]="limitTo()">
    <div class="text-steel-20 mb-2 text-lg font-semibold">
      {{ 'footer.location-links.' + verticalType + '.' + (isAgent ? 'agents' : 'agencies') | lowercase | transloco }}
    </div>
    @for (locationLink of locationLinks; track locationLink) {
      <div class="mb-2">
        <a
          class="font-normal !no-underline"
          [routerLink]="isAgent ? locationLink[verticalType | lowercase].agentUrl : locationLink[verticalType | lowercase].agencyUrl">
          {{ locationLink.name }}
        </a>
      </div>
    }
  </div>
  @if (hasMore()) {
    <div class="mb-2">
      <button class="text-highlight-blue hover:text-blue-10 inline-flex rounded-sm font-bold" (click)="toggleLimit()" type="button">
        {{ 'footer.location-links.view.' + (limitTo() ? 'more' : 'less') | transloco }}
        <rma-icon class="ml-1" [glyph]="limitTo() ? 'expand_more' : 'expand_less'" />
      </button>
    </div>
  }
</nav>
