/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { CurrentUserService } from '@rma/accounts/user';
import { DynamicAssetLoaderService, DynamicLoaderAssets } from '@rma/generic/util/dynamic-asset-loader';
import { FeatureFlags, FeatureProviderService } from '@rma/generic/util/feature-flags';
import { filter, switchMap, take } from 'rxjs/operators';
import { ChatApiService } from '../data-access/chat-api.service';

declare global {
  interface Window {
    intercomSettings?: any;
  }
  function Intercom(value: string, params?: any): void;
}

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  public isLoaded$ = new EventEmitter<boolean>();

  constructor(
    private readonly chatApiService: ChatApiService,
    private readonly userService: CurrentUserService,
    private readonly dynamicAssetLoader: DynamicAssetLoaderService,
    private readonly featureProvider: FeatureProviderService,
    @Inject(WINDOW) private readonly window: Window,
  ) {}

  public initialise(): void {
    this.featureProvider
      .getFeature$(FeatureFlags.EnableIntercom, false)
      .pipe(
        filter((x) => x),
        switchMap(() => this.userService.currentUser$),
        filter((x) => x !== null && !x.isImpersonating),
        switchMap(() => this.chatApiService.userChatData$.pipe(take(1))),
        filter((x) => !!x.app_id),
        take(1),
      )
      .subscribe((x) => {
        this.window.intercomSettings = x;
        this.dynamicAssetLoader.load(DynamicLoaderAssets.Intercom).subscribe((y) => this.isLoaded$.emit(y));
      });
  }

  public showMessenger(): void {
    Intercom('show');
  }
}
