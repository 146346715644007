<header class="border-steel-5 fixed inset-x-0 top-0 z-50 h-16 items-center justify-between border-b bg-white px-3 sm:px-5 md:px-6">
  <div class="flex items-center">
    <a class="shrink-0 pt-1 sm:mr-2 md:mr-4" routerLink="/">
      <img class="logo hidden sm:block" [src]="'/logos/logo-dark.svg' | staticImageUrl" alt="RateMyAgent" width="120" height="36" />
      <img class="tiny-logo sm:hidden" [src]="'/logos/logo-stacked.svg' | staticImageUrl" alt="RateMyAgent" width="64" height="36" />
    </a>
    <div class="transition-opacity" [class.!opacity-0]="basicHeader">
      <rma-header-nav class="hidden h-16 lg:block" />
      <a class="search-icon ml-2 mt-2" routerLink="/search" aria-label="Search">
        <rma-icon class="text-steel-100" [glyph]="'search'" legacySize="1.8rem" />
      </a>
    </div>
  </div>

  <div class="hidden transition-opacity lg:block" [class.!opacity-0]="basicHeader">
    <a class="border-steel-10 flex w-full items-center justify-center rounded-full border-2 p-1 !no-underline" routerLink="/search">
      <rma-icon class="mr-2" [glyph]="'search'" />
      <span>{{ 'header.link.search' | transloco }}</span>
    </a>
  </div>

  <div class="flex h-full items-center justify-end gap-2 transition-opacity" [class.!opacity-0]="basicHeader">
    @if (landerUrl$ | async; as url) {
      <a class="!hidden md:!flex" [href]="url" mat-flat-button color="primary" data-id="agent-lander-link">
        {{ 'header.link.agentLander' | transloco }}
      </a>
    }
    <rma-header-compare-agent />
    <rma-header-track-property />
    <rma-header-user />
    <button class="menu" (click)="toggleMenu()" type="button" rma-icon-button aria-label="Menu">
      <rma-icon [glyph]="'menu'" />
    </button>
  </div>
</header>
