import { APP_INITIALIZER, EnvironmentProviders, NgZone, makeEnvironmentProviders } from '@angular/core';
import { Environment, PlatformService } from '@rma/generic/util/environment';
import { LaunchDarklyClient } from '@rma/generic/util/feature-flags';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { HeapScriptService } from './heap-script.service';

function initialiseHeap(
  environment: Environment,
  platformService: PlatformService,
  launchDarklyClient: LaunchDarklyClient,
  heapScriptService: HeapScriptService,
  zone: NgZone,
): () => Promise<void> {
  return () =>
    firstValueFrom(
      launchDarklyClient.onLoad$().pipe(
        map(() => {
          if (platformService.isPlatformBrowser && environment.tracking.heapAppId) {
            zone.runOutsideAngular(() => heapScriptService.initialiseHeap());
          }
        }),
      ),
    );
}

export function provideRmaHeap(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: initialiseHeap,
      deps: [Environment, PlatformService, LaunchDarklyClient, HeapScriptService, NgZone],
      multi: true,
    },
  ]);
}
