import { EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
// import { provideClientHydration }  '@angular/platform-browser';
import { AuthenticationShellModule } from '@rma/accounts/public/orc-authentication';
import { provideRmaUser } from '@rma/accounts/user';
import { provideRmaTranslation } from '@rma/generic/orc/translation';
import { provideRmaLocale } from '@rma/generic/util/environment';
import { provideRmaLaunchDarklyBrowser } from '@rma/generic/util/feature-flags';
import { provideRmaHttpClient } from '@rma/generic/util/http';
import { provideRmaMaterial } from '@rma/generic/util/material';
import { provideMetaInitializer } from '@rma/generic/util/meta-service';
import { provideRmaGoogleTracking } from '@rma/generic/util/tracking/analytics';
import { provideRmaHeap } from '@rma/generic/util/tracking/heap';
import { provideRmaTrackJs } from '@rma/generic/util/tracking/track-js';
import { Angulartics2Module } from 'angulartics2';
import { provideLowerCaseUrlSerializer } from './util-lowercase-url-serialiser/lowercase-url-serializer.provider';

export function provideRmaBootstrap(): EnvironmentProviders {
  return makeEnvironmentProviders([
    importProvidersFrom(Angulartics2Module.forRoot(), AuthenticationShellModule.forRoot()),
    provideLowerCaseUrlSerializer(),
    provideMetaInitializer(),
    // provideRmaAuth(),
    provideRmaGoogleTracking(),
    provideRmaHeap(),
    provideRmaHttpClient(),
    provideRmaLaunchDarklyBrowser(),
    provideRmaLocale(),
    provideRmaMaterial(),
    provideRmaTrackJs(),
    provideRmaTranslation(),
    provideRmaUser(),
  ]);
}
