import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { TrackPropertyService } from '@rma/campaigns/private/api-track-campaign';
import { HeaderCountButtonComponent } from '@rma/generic/ui/count-button';

@Component({
  selector: 'rma-header-track-property',
  templateUrl: './header-track-property.component.html',
  styleUrls: ['./header-track-property.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeaderCountButtonComponent, RouterLink, AsyncPipe, TranslocoPipe],
})
export class HeaderTrackPropertyComponent {
  constructor(protected readonly trackPropertyService: TrackPropertyService) {}
}
