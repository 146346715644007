<ng-template #authenticatedTemplate />
<ng-container *rmaLet="isAuthenticated$ as isAuthenticated; suspenseRef: suspense">
  @if (!isAuthenticated) {
    <a class="login-link" [routerLink]="loginLink" mat-button color="primary">{{ 'header.menu.login' | transloco }}</a>
    <div class="ml-2 hidden lg:block">
      <a [routerLink]="joinLink" rel="nofollow" mat-stroked-button color="primary" data-id="join-rma-button">{{
        'header.menu.join' | transloco
      }}</a>
    </div>
  }
</ng-container>

<ng-template #suspense>
  <div class="ml-6 mr-2">
    @if (showLoading) {
      <mat-spinner [diameter]="35" [strokeWidth]="5" />
    }
  </div>
</ng-template>
