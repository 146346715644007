import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ShortlistService } from '@rma/compare-agent/private-api/da-compare-agent';
import { HeaderCountButtonComponent } from '@rma/generic/ui/count-button';
import { Observable } from 'rxjs/internal/Observable';

@UntilDestroy()
@Component({
  selector: 'rma-header-compare-agent',
  templateUrl: './compare-agent.component.html',
  styleUrls: ['./compare-agent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeaderCountButtonComponent, RouterLink, AsyncPipe],
})
export class HeaderCompareAgentComponent {
  public count$: Observable<number>;

  constructor(private readonly shortlistService: ShortlistService) {
    this.count$ = this.shortlistService.shortlistCount$;
  }
}
