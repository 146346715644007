import { Injectable } from '@angular/core';
import { CurrentUserService, UserType } from '@rma/accounts/user';
import { map } from 'rxjs/operators';
import { TrackableUserType } from '../domain';
import { GoogleTagManagerService } from '../feat-google-tag-manager/google-tag-manager.service';

@Injectable({
  providedIn: 'root',
})
export class UserTrackerService {
  constructor(
    private readonly googleTagManagerService: GoogleTagManagerService,
    private readonly currentUserService: CurrentUserService,
  ) {}

  watchUser() {
    this.currentUserService.currentUserContext$
      .pipe(
        map((x) => (x ? { userType: this.mapUserType(x.userType), userId: x.userId } : { userType: TrackableUserType.Unauthenticated })),
      )
      .subscribe((x) => this.googleTagManagerService.trackUser(x));
  }

  private mapUserType(userType: UserType) {
    switch (userType) {
      case UserType.Agency:
        return TrackableUserType.Agency;
      case UserType.Agent:
        return TrackableUserType.Agent;
      case UserType.Consumer:
        return TrackableUserType.Consumer;
      default:
        return TrackableUserType.Unauthenticated;
    }
  }
}
