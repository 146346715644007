import { OverlayRef } from '@angular/cdk/overlay';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation,
  afterNextRender,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterModule,
} from '@angular/router';
import { RmaIconComponent } from '@rma/generic/ui/icon';
import type { RmaOverlayService } from '@rma/generic/ui/overlay';
import { ViewportHeightService } from '@rma/generic/util/device-size';
import { LazyInject } from '@rma/generic/util/lazy-inject';
import { filter, map, mergeMap } from 'rxjs/operators';
import { FooterComponent } from '../feat-footer/footer.component';
import { HeaderComponent } from '../feat-header/header.component';
import { LazyLoadThirdPartyScriptsService } from '../util-lazy-load-scripts/lazy-load-scripts';

@Component({
  selector: 'rma-root',
  templateUrl: './rma-layout.component.html',
  styleUrls: ['./rma-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [LazyLoadThirdPartyScriptsService],
  imports: [MatProgressSpinnerModule, RmaIconComponent, RouterModule, HeaderComponent, FooterComponent],
})
export class RmaLayoutComponent implements OnInit {
  public basicHeader: boolean;
  public hideFooter: boolean;
  public hideHeader: boolean;
  public isLoading: boolean;
  public isNew: boolean;
  public showSideNav: boolean;
  private sideNavRef?: OverlayRef;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly lazyInjector: LazyInject,
    private readonly vcr: ViewContainerRef,
    private readonly cdr: ChangeDetectorRef,
    _viewportHeightService: ViewportHeightService,
    lazyLoad3rdPartyScripts: LazyLoadThirdPartyScriptsService,
  ) {
    afterNextRender(() => lazyLoad3rdPartyScripts.initialize());
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe(({ hideFooter, hideHeader, basicHeader }) => {
        this.hideHeader = !!hideHeader;
        this.hideFooter = !!hideFooter;
        this.basicHeader = !!basicHeader;
        this.hideSideNav();
      });

    this.router.events.subscribe((e: Event) => this.setLoading(e));
  }

  public toggleSideNav() {
    this.showSideNav = !this.showSideNav;
    this.getSideNavComponent(this.showSideNav);
  }

  private setLoading(event: Event): void {
    if (event instanceof NavigationStart) {
      this.isLoading = true;
    }
    if (event instanceof NavigationEnd) {
      this.isLoading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.isLoading = false;
    }
    if (event instanceof NavigationError) {
      this.isLoading = false;
    }
  }

  private async getSideNavComponent(show: boolean) {
    if (show) {
      const overlayService = await this.lazyInjector.get<RmaOverlayService>(() =>
        import('@rma/generic/ui/overlay').then((m) => m.RmaOverlayService),
      );

      const { SidenavComponent } = await import('@rma/site/private/ui-mobile-side-nav');

      const { overlayRef } = overlayService.open(this.vcr, SidenavComponent, {
        hasBackdrop: true,
        backdropClass: 'mobile-side-nav',
        disposeOnNavigation: true,
        scrollStrategy: overlayService.overlay.scrollStrategies.block(),
        positionStrategy: overlayService.overlay.position().global().right().top('64px'),
      });

      this.sideNavRef = overlayRef;

      this.sideNavRef?.backdropClick().subscribe(() => this.hideSideNav());
    } else {
      this.sideNavRef?.detach();
    }

    this.cdr.markForCheck();
  }

  private hideSideNav() {
    this.sideNavRef?.detach();
    this.showSideNav = false;
  }
}
