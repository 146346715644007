@if (!hideHeader) {
  <rma-header [basicHeader]="basicHeader" (toggle)="toggleSideNav()" />
}

<section class="rma-content">
  <div class="rma-loader" [class.visible]="isLoading" [class.invisible]="!isLoading">
    @if (isLoading) {
      <mat-spinner />
    }
  </div>

  <router-outlet />

  @if (!hideFooter) {
    <rma-footer />
  }
</section>
