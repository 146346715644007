import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { CurrentUserService } from '@rma/accounts/user';
import { LetDirective } from '@rma/generic/ui/let';
import { PlatformService } from '@rma/generic/util/environment';
import { AvatarStandard } from '@rma/generic/util/pipes/image-optimiser';
import { Observable, from } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';

@Component({
  selector: 'rma-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LetDirective, MatButtonModule, RouterLink, MatProgressSpinnerModule, TranslocoPipe],
})
export class HeaderUserComponent implements OnInit {
  @ViewChild('authenticatedTemplate', { read: ViewContainerRef })
  authenticatedTemplate: ViewContainerRef;

  public isAuthenticated$: Observable<boolean>;
  public joinLink = '/account/join';
  public loginLink = '/profile/login';
  public showLoading: boolean;

  public readonly avatarOptimiseSize: string = AvatarStandard;

  constructor(
    private readonly userService: CurrentUserService,
    private readonly platformService: PlatformService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isAuthenticated$ = this.userService.isAuthenticated$.pipe(
      filter(() => this.platformService.isPlatformBrowser || this.platformService.isPlatformBrowserBot),
      concatMap((isAuthenticated) => from(this.loadAuthUserComponent(isAuthenticated)).pipe(map(() => isAuthenticated))),
    );

    this.showLoading = this.platformService.isPlatformBrowser;
  }

  private async loadAuthUserComponent(isAuthenticated: boolean) {
    this.authenticatedTemplate?.clear();

    if (isAuthenticated) {
      const { HeaderAuthComponent } = await import('@rma/site/private/orc-user-header');
      this.authenticatedTemplate.createComponent(HeaderAuthComponent);

      this.cdr.markForCheck();
    }
  }
}
