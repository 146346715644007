import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FeatureFlags, FeatureProviderService } from '@rma/generic/util/feature-flags';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { HeaderBrowseModeToggleComponent } from '../feat-header-browse-mode-toggle/header-browse-mode-toggle.component';

@Component({
  selector: 'rma-header-nav',
  template: `@if (enableToggleMode$ | async) {
    <rma-header-browse-mode-toggle />
  }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeaderBrowseModeToggleComponent, AsyncPipe],
})
export class HeaderNavComponent implements OnInit {
  public enableToggleMode$: Observable<boolean>;

  constructor(private readonly featureProviderService: FeatureProviderService) {}

  ngOnInit(): void {
    const hasFinance$ = this.featureProviderService.getFeature$(FeatureFlags.EnableFinance, false);
    const hasLeasing$ = this.featureProviderService.getFeature$(FeatureFlags.EnableLeasing, false);

    this.enableToggleMode$ = combineLatest([hasFinance$, hasLeasing$]).pipe(map(([x, y]) => x || y));
  }
}
