@if (browseMode$ | async; as browseMode) {
  <button
    [color]="browseMode === browseModes.Sales ? 'primary' : ''"
    (click)="setMode(browseModes.Sales)"
    type="button"
    mat-button
    angulartics2On="click"
    angularticsCategory="Consumer Interaction"
    angularticsAction="Top-Nav - Sales">
    {{ 'header.link.sales' | transloco }}
  </button>
  <button
    [color]="browseMode === browseModes.Leasing ? 'primary' : ''"
    (click)="setMode(browseModes.Leasing)"
    type="button"
    mat-button
    angulartics2On="click"
    angularticsCategory="Consumer Interaction"
    angularticsAction="Top-Nav - Leasing">
    {{ 'header.link.leasing' | transloco }}
  </button>
  @if (enableFinance$ | async) {
    <button
      class="broker-link"
      [color]="browseMode === browseModes.Finance ? 'primary' : ''"
      (click)="setMode(browseModes.Finance)"
      type="button"
      mat-button
      angulartics2On="click"
      angularticsCategory="Consumer Interaction"
      angularticsAction="Top-Nav - Mortgage Brokers">
      {{ 'header.link.finance' | transloco }}
    </button>
  }
}
