export interface SocialLink {
  label: string;
  icon: 'social-facebook' | 'social-instagram' | 'social-linkedin' | 'social-twitter';
  href: string;
}

// this should be not hardcoded and somewhere else???
export const excludedLocations: string[] = [
  'American Samoa',
  'Commonwealth Of The Northern Mariana Islands',
  'Guam',
  'United States Virgin Islands',
];
