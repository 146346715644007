import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { BrowserCookiesModule } from '@ngx-utils/cookies/browser';
import { AbTestModule, experiements } from '@rma/generic/ui/ab-test';
import { provideRmaBootstrap } from '@rma/site/public/orc-layout';
import { routes } from './routing/app.routes';
import { routerConfig, scrollConfig } from './routing/routing.config';

export const config: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserCookiesModule.forRoot(), BrowserModule, AbTestModule.forRoot(experiements)),
    provideAnimations(),
    // This is erroring on the server.
    // Hopefully 0.14.3 Zone.js fixes this :https://github.com/angular/angular/pull/53630
    // provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideHttpClient(withInterceptorsFromDi()),
    provideRmaBootstrap(),
    provideRouter(routes, withEnabledBlockingInitialNavigation(), withRouterConfig(routerConfig), withInMemoryScrolling(scrollConfig)),
    provideClientHydration(),
  ],
};
