import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { RmaIconComponent } from '@rma/generic/ui/icon';

@Component({
  selector: 'rma-header-count-button',
  templateUrl: './header-count-button.component.html',
  styleUrls: ['./header-count-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RmaIconComponent],
})
export class HeaderCountButtonComponent {
  @HostBinding('class')
  @Input()
  classes = 'flex';

  @Input()
  title: string;

  @Input()
  matIcon: 'favorite' | 'group';

  @Input()
  count: number | null = null;

  @Input()
  srSubtitle: string;

  @Output()
  toggled = new EventEmitter<void>();

  onToggled(_$event: Event) {
    this.toggled.emit();
  }
}
